/**
 * Redefine font-face with )bower_components/ , this function is provided by sass-rails.
 */

/* FontAwesome */

@font-face {
  font-family: 'FontAwesome';
  src: 'bower_components/fontawesome/fonts/fontawesome-webfont.eot?v=4.2.0';
  src: 'bower_components/fontawesome/fonts/fontawesome-webfont.eot?#iefix&v=4.2.0' format('embedded-opentype'), 
  'bower_components/fontawesome/fonts/fontawesome-webfont.woff?v=4.2.0' format('woff'), 
  'bower_components/fontawesome/fonts/fontawesome-webfont.ttf?v=4.2.0' format('truetype'), 
  'bower_components/fontawesome/fonts/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular' format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Simple-Line-Icons */

@font-face {
  font-family: 'Simple-Line-Icons';
  src:'bower_components/simple-line-icons/fonts/Simple-Line-Icons.eot';
  src:'bower_components/simple-line-icons/fonts/Simple-Line-Icons.eot?#iefix' format('embedded-opentype'),
    'bower_components/simple-line-icons/fonts/Simple-Line-Icons.woff' format('woff'),
    'bower_components/simple-line-icons/fonts/Simple-Line-Icons.ttf' format('truetype'),
    'bower_components/simple-line-icons/fonts/Simple-Line-Icons.svg#Simple-Line-Icons' format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Weather Icons */

@font-face {
  font-family: 'weathericons';
  src: 'bower_components/weather-icons/font/weathericons-regular-webfont.eot';
  src: 'bower_components/weather-icons/font/weathericons-regular-webfont.eot?#iefix' format('embedded-opentype'), 
  'bower_components/weather-icons/font/weathericons-regular-webfont.woff' format('woff'), 
  'bower_components/weather-icons/font/weathericons-regular-webfont.ttf' format('truetype'), 
  'bower_components/weather-icons/font/weathericons-regular-webfont.svg#weathericons-regular-webfontRg' format('svg');
  font-weight: normal;
  font-style: normal;
}

